import { get } from "svelte/store";
import { register, init } from "svelte-i18n";
import { querystring } from "svelte-spa-router";

const DEFAULT_LANGUAGES = "en";
const DEFAULT_RELEASE = "latest";

const languages = env?.LOCIZE_LANGUAGES
  ? env?.LOCIZE_LANGUAGES.split(",")
  : DEFAULT_LANGUAGES.split(",");
const release = env?.LOCIZE_RELEASE || DEFAULT_RELEASE;

languages.forEach((language) =>
  register(language, async () => {
    const commonRes = await fetch(
      `https://api.locize.app/31acd68d-93cc-4e1b-a27b-f6be754c5792/${release}/${language}/common`
    );
    const apiRes = await fetch(
      `https://api.locize.app/31acd68d-93cc-4e1b-a27b-f6be754c5792/${release}/${language}/api`
    );

    const common = await commonRes.json();
    const api = await apiRes.json();

    return {
      ...common,
      api: {
        ...api,
      },
    };
  })
);

const searchParams = new URLSearchParams(get(querystring));
const initialLocale = searchParams.get("lang");

init({
  initialLocale,
  fallbackLocale: "en",
});

const rtlLngs = [
  "ar",
  "shu",
  "sqr",
  "ssh",
  "xaa",
  "yhd",
  "yud",
  "aao",
  "abh",
  "abv",
  "acm",
  "acq",
  "acw",
  "acx",
  "acy",
  "adf",
  "ads",
  "aeb",
  "aec",
  "afb",
  "ajp",
  "apc",
  "apd",
  "arb",
  "arq",
  "ars",
  "ary",
  "arz",
  "auz",
  "avl",
  "ayh",
  "ayl",
  "ayn",
  "ayp",
  "bbz",
  "pga",
  "he",
  "iw",
  "ps",
  "pbt",
  "pbu",
  "pst",
  "prp",
  "prd",
  "ug",
  "ur",
  "ydd",
  "yds",
  "yih",
  "ji",
  "yi",
  "hbo",
  "men",
  "xmn",
  "fa",
  "jpr",
  "peo",
  "pes",
  "prs",
  "dv",
  "sam",
  "ckb",
];

export const handleOnSetDirection = (lng) => {
  const direction = rtlLngs.includes(lng) ? "rtl" : "ltr";

  document.documentElement.setAttribute("dir", direction);
};
