<script>
  export let id;
  export let name;
  export let value;
  export let type = "text";
  export let placeholder;
  export let disabled;
  export let required;
  export let pattern;
  export let minlength;
  export let maxlength;
</script>

{#if type === "text"}
  <input
    dir="ltr"
    type="text"
    class={`focus:ring-blue-900 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
      disabled ? "bg-disabled cursor-not-allowed" : ""
    }`}
    bind:value
    {id}
    {name}
    {placeholder}
    {disabled}
    {required}
    {pattern}
    {minlength}
    {maxlength}
  />
{:else if type === "email"}
  <input
    dir="ltr"
    type="email"
    class={`focus:ring-blue-900 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
      disabled ? "bg-disabled cursor-not-allowed" : ""
    }`}
    bind:value
    {id}
    {name}
    {placeholder}
    {disabled}
    {required}
    {pattern}
    {minlength}
    {maxlength}
  />
{:else if type === "tel"}
  <input
    dir="ltr"
    type="tel"
    class={`focus:ring-blue-900 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
      disabled ? "bg-disabled cursor-not-allowed" : ""
    }`}
    bind:value
    {id}
    {name}
    {placeholder}
    {disabled}
    {required}
    {pattern}
    {minlength}
    {maxlength}
  />
{:else if type === "number"}
  <input
    dir="ltr"
    type="text"
    inputmode="numeric"
    class={`focus:ring-blue-900 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
      disabled ? "bg-disabled cursor-not-allowed" : ""
    }`}
    bind:value
    {id}
    {name}
    {placeholder}
    {disabled}
    {required}
    {pattern}
    {minlength}
    {maxlength}
  />
{/if}

<style>
  input:invalid {
    /* @apply border-red-900 border-opacity-10 hover:border-opacity-50 focus:border-opacity-100; */
  }
</style>
