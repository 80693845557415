<script>
  export let htmlFor;
  export let label;
  export let error;
</script>

<div class="stack-2">
  <label for={htmlFor} class="block text-sm font-medium text-gray-700 dark:text-gray-300 mx-3 mb-1">{label}</label>
<slot />
{#if error}
  <p class="text-red-500 text-sm mt-1 mx-3">{error}</p>
{/if}
</div>
