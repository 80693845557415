<script>
  export let block = true;

  let className;

  export { className as class };
</script>

<span
  class={`bg-gray-300 dark:bg-gray-600 rounded-lg inline-block animate-pulse ${
    block ? "w-full" : ""
  } ${className}`}
/>
