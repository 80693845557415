<script>
  import { fly } from "svelte/transition";
  import Icon from "svelte-awesome";
  import {
    faCheck,
    faExclamation,
    faExclamationTriangle,
    faInfo,
  } from "@fortawesome/free-solid-svg-icons";

  export let type = "info";
  export let message;

  let onClose = () => {};

  const icons = {
    success: faCheck,
    error: faExclamation,
    warning: faExclamationTriangle,
    info: faInfo,
  };

  // TODO: Cannot use `bg-${type}` because output CSS doesn't contain such classes
  // Need to figure out why
  const bg = {
    success: "bg-success",
    error: "bg-error",
    warning: "bg-warning",
    info: "bg-info",
  };
  const text = {
    success: "text-success",
    error: "text-error",
    warning: "text-warning",
    info: "text-info",
  };
</script>

<div
  role="alert"
  class="relative py-2 px-4 bg-surface shadow rounded-lg max-w-md w-full flex justify-between items-center"
  transition:fly={{ y: -100 }}
>
  <div
    class={`absolute left-0 h-full w-1 rounded-tl-lg rounded-bl-lg ${bg[type]}`}
  />
  <div class="flex items-center">
    <Icon data={icons[type]} class={`mr-3 ${text[type]} flex-shrink-0`} />
    <span class="text-sm">{message}</span>
  </div>
  <button type="button" onClick={onClose}>
    <!-- <XIcon class="w-4 h-4" /> -->
    <span class="sr-only">Close Notification</span>
  </button>
</div>
