<script>
  import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
  import { onMount } from "svelte";
  import Button from "../primitives/Button.svelte";
  import { theme } from "../store";

  let isDarkTheme = document.documentElement.classList.contains("dark");

  onMount(() => {
    if (
      (!$theme && window.matchMedia("(prefers-color-scheme: dark)").matches) ||
      $theme === "dark"
    ) {
      document.documentElement.classList.add("dark");
      isDarkTheme = document.documentElement.classList.contains("dark");
    }
  });

  const handleOnChangeTheme = () => {
    document.documentElement.classList.toggle("dark");

    isDarkTheme = document.documentElement.classList.contains("dark");

    theme.set(isDarkTheme ? "dark" : "light");
  };
</script>

<header class="p-6 pb-0 max-w-lg mx-auto w-full flex justify-end">
  {#if isDarkTheme}
    <Button icon={faMoon} on:click={handleOnChangeTheme} />
  {:else}
    <Button icon={faSun} on:click={handleOnChangeTheme} />
  {/if}
</header>
