// import { writable } from "svelte/store";
import { sessionStore } from "./sessionStore";

export const formDefaultProps = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  day: "",
  month: "",
  year: "",
  phone: "",
};

export const form = sessionStore("form", formDefaultProps);
export const submittedUuid = sessionStore('submittedUuid', null);
export const submittedFirstName = sessionStore('submittedFirstName', null);
export const theme = sessionStore('theme', null);
