<script>
  import { onMount } from "svelte";
  import { t, locales } from "svelte-i18n";
  import { querystring, replace } from "svelte-spa-router";
  import Layout from "../components/Layout.svelte";
  import SelectLanguage from "../components/SelectLanguage.svelte";
  import Button from "../primitives/Button.svelte";
  import {
    pushWithQuerystring,
  } from '../utils/routing';

  onMount(() => {
    replace($querystring ? `/?${$querystring}` : `/`)
  })
</script>

<Layout title="welcome.label" description="welcome.description">
  <div class="stack-6">
    {#if $locales?.length > 1}
      <div class="bg-surface p-6 stack-6 rounded-lg shadow">
        <SelectLanguage />
      </div>
    {/if}
    <Button type="primary" on:click={() => pushWithQuerystring('/emergency')} block
      >{$t("continue")}</Button
    >
  </div>
</Layout>
