<script>
  import { afterUpdate } from "svelte";
  import { fade } from "svelte/transition";
  import { t, isLoading } from "svelte-i18n";
  import Footer from "./Footer.svelte";
  import Skeleton from "../primitives/Skeleton.svelte";
  import Markdown from "./Markdown.svelte";

  export let title;
  export let titleProps = {};
  export let description;

  afterUpdate(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  });
</script>

<main class="p-6 max-w-lg mx-auto w-full">
  <section class="stack-6 flex justify-center h-full">
    {#if $isLoading}
      <div in:fade>
        <Skeleton class="h-6" />
        <Skeleton class="h-24" />
      </div>
    {:else if title || description}
      <div class="stack-6">
        {#if title}
          <h1 class="text-xl font-medium" in:fade>{$t(title, titleProps)}</h1>
        {/if}
        {#if description}
          <p class="text-gray-500 dark:text-gray-300" in:fade>
            <Markdown source={$t(description)} />
          </p>
        {/if}
      </div>
    {/if}
    {#if !$isLoading}
      <div in:fade>
        <slot />
      </div>
    {:else}
      <Skeleton class="h-48" />
    {/if}
  </section>
</main>
<Footer />
