<script>
  import "intl-tel-input/build/css/intlTelInput.css";
  import intlTelInput from "intl-tel-input";
  import * as utilsScript from 'intl-tel-input/build/js/utils';
  import { onDestroy, onMount } from "svelte";

  export let input;
  export let value;
  export let disabled;
  export let required;

  let ref;

  onMount(() => {
    input = intlTelInput(ref, {
      separateDialCode: true,
      initialCountry: "au",
      utilsScript,
    });
  });

  onDestroy(() => {
    if (input) {
      input.destroy();
    }
  });
</script>

<input type="tel" bind:this={ref} bind:value {disabled} {required} />
