<script>
  import { fly } from "svelte/transition";
  import { backOut } from "svelte/easing";
  import { circleONotch } from "svelte-awesome/icons";
  import Icon from "svelte-awesome";

  export let htmlType = "button";
  export let type;
  export let icon;
  export let loading;
  export let disabled;
  export let block;

  let className =
    "shadow-sm flex justify-center items-center no-underline focus:outline-none focus:ring-2 focus:ring-black transition-colors overflow-hidden";

  if (!block) {
    className += " max-w-xxs";
  }

  if (type === "primary") {
    className += " bg-primary text-white";
  } else {
    className += " bg-surface text-primary";
  }

  if (icon) {
    className +=
      " h-10 w-10 bg-surface rounded-full text-gray-500 dark:text-white";
  } else {
    className += "  h-12 w-full px-4 rounded-lg font-medium tracking-wide";
  }
</script>

<button type={htmlType} class={className} {disabled} on:click>
  {#if loading}
    <div in:fly={{ y: 50, easing: backOut }}>
      <Icon data={circleONotch} class="animate-spin mr-3" />
    </div>
  {/if}
  {#if icon}
    <div class=" leading-4" in:fly={{ y: 50, easing: backOut }}>
      <Icon data={icon} />
    </div>
  {/if}
  <slot />
</button>

<!-- TODO: add hover state -->
<style>
  button:disabled {
    @apply bg-disabled text-secondary cursor-not-allowed;
  }
</style>
