<script>
  import { onDestroy } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { t, locale } from "svelte-i18n";
  import { push, querystring } from "svelte-spa-router";
  import FormItem from "../components/FormItem.svelte";
  import Input from "../components/Input.svelte";
  import Layout from "../components/Layout.svelte";
  import Button from "../primitives/Button.svelte";
  import {
    form as formStore,
    formDefaultProps,
    submittedFirstName,
    submittedUuid,
  } from "../store";
  import { addNotification } from "../store/notifications";
  import { formatDate } from "../utils/date";
  import { pushWithQuerystring } from "../utils/routing";

  const GCLOUD_API_KEY = "AIzaSyD9hKLgHsdvl15yb4RysEY59lnYjDnLNn0";
  const GCLOUD_PROJECT_ID = "zedoc-io";
  const GCLOUD_SITE_KEY = "6Ldk2L0eAAAAAOknE1gJMzoDXJWCuJq5dWuqy5QJ";
  const GCLOUD_ACTION = "LOGIN";
  const GCLOUD_PASS_SCORE = 0.5;
  const VERIFICATION_CODE_LENGTH = 6;
  const REQUEST_TIMES = 20;

  let interval;
  let i = REQUEST_TIMES;
  let isCodeRequesting = false;
  let isCodeRequested = false;
  let canRequestCode = false;
  let isCodeVerifying = false;
  let hasSubmitError = false;
  let submitError = "";
  let validateErrors = {};

  const redirectToErrorScreen = () => {
    const searchParams = new URLSearchParams($querystring);
    searchParams.set("type", "recaptcha");
    push(`/error?${searchParams.toString()}`);
  };

  const handleSetRequestInterval = () => {
    if (!interval) {
      interval = setInterval(() => {
        if (i === 1) {
          clearInterval(interval);
          canRequestCode = true;
        } else {
          i -= 1;
        }
      }, 1000);
    }
  };

  const validateRecaptcha = async () => {
    try {
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(GCLOUD_SITE_KEY, {
          action: GCLOUD_ACTION,
        });
        const res = await fetch(
          `https://recaptchaenterprise.googleapis.com/v1beta1/projects/${GCLOUD_PROJECT_ID}/assessments?key=${GCLOUD_API_KEY}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              event: {
                token,
                siteKey: GCLOUD_SITE_KEY,
                expectedAction: GCLOUD_ACTION,
              },
            }),
          }
        );
        const data = await res.json();

        if (
          data?.tokenProperties.action !== GCLOUD_ACTION ||
          data?.score <= GCLOUD_PASS_SCORE
        ) {
          validateErrors.gcloud = "";
          redirectToErrorScreen();
        }
      });
    } catch (err) {
      console.log(err);
      validateErrors.gcloud = "";
      redirectToErrorScreen();
    }
  };
  const validateVerificationCode = async ({ values }) => {
    try {
      const res = await fetch("/api/isphonevalid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: $formStore.uuid,
          phone: $formStore.phone,
          code: Number(values.verificationCode),
        }),
      });

      const data = await res.json();

      if (res.ok) {
        addNotification({
          type: "success",
          message: $t(data?.key || data?.message),
        });
      } else {
        validateErrors.verificationCode = data?.key || data?.message;
      }

      isCodeVerifying = false;
    } catch (err) {
      console.log(err);
      validateErrors.verificationCode = $t("api.wrongCode");
      isCodeVerifying = false;
    }
  };

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      verificationCode: "",
    },
    validate: async (values) => {
      validateErrors = {};

      if (isCodeRequesting || isCodeVerifying) {
        validateErrors.cannotSubmit = "";
      } else if (!values.verificationCode) {
        validateErrors.verificationCode = $t("form.requiredField");
      } else if (values.verificationCode.length !== VERIFICATION_CODE_LENGTH) {
        validateErrors.verificationCode = $t("api.wrongCode");
      } else {
        isCodeVerifying = true;

        await validateVerificationCode({ values });
        await validateRecaptcha();
      }

      return validateErrors;
    },
    onSubmit: async (values) => {
      // TODO: Verify SMS as well

      isCodeVerifying = true;
      hasSubmitError = false;

      try {
        const res = await fetch("/api/submitform", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uuid: $formStore.uuid,
            firstName: $formStore.firstName,
            middleName: $formStore.middleName,
            lastName: $formStore.lastName,
            email: $formStore.email,
            dateOfBirth: formatDate(
              $formStore.day,
              $formStore.month,
              $formStore.year
            ),
            phone: $formStore.phone,
            lang: $locale,
          }),
        });

        if (res.ok) {
          submittedUuid.set($formStore.uuid);
          submittedFirstName.set($formStore.firstName);
          // Reset form
          formStore.set(formDefaultProps);
          pushWithQuerystring("/thank-you");
        }
      } catch (err) {
        console.log(err);
        hasSubmitError = true;
      }
    },
  });

  const handleOnRequestCode = async () => {
    if (isCodeRequesting || isCodeVerifying) {
      return;
    }

    isCodeRequesting = true;

    try {
      const res = await fetch("/api/isphonevalid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: $formStore.uuid,
          phone: $formStore.phone,
          lang: $locale,
        }),
      });

      const data = await res.json();
      console.log(res, data);

      if (res.ok) {
        isCodeRequested = true;
        handleSetRequestInterval();
        addNotification({
          type: "success",
          message: $t(data?.key || data?.message),
        });
      } else {
        hasSubmitError = true;
        submitError = data?.key || data?.message;
      }

      isCodeRequesting = false;
    } catch (err) {
      console.log(err);
      hasSubmitError = true;
      submitError = data?.key || data?.message;
    }
  };
  const handleOnGoBack = () => {
    if (isCodeRequesting || isCodeVerifying) {
      return;
    }

    pushWithQuerystring("/form");
  };

  onDestroy(() => clearInterval(interval));
</script>

<Layout
  title={isCodeRequested ? "submitVerificationCode.title" : "verify.label"}
  description={isCodeRequested
    ? "submitVerificationCode.description"
    : "verify.description"}
>
  <div class="stack-6">
    {#if hasSubmitError}
      <p class="text-error text-center">
        {$t(submitError)}
      </p>
    {/if}
    {#if !isCodeRequested}
      {#if $formStore.email}
        <div class="stack-6">
          <div class="cluster-6">
            <Button on:click={handleOnGoBack} disabled={isCodeRequesting} block
              >{$t("changeNumber")}</Button
            >
            <Button
              type="primary"
              on:click={handleOnRequestCode}
              loading={isCodeRequesting}
              disabled={isCodeRequesting}
              block>{$t("requestCode")}</Button
            >
          </div>
          <p class="text-sm text-center">
            {$t("verify.sentTo", { values: { sentTo: $formStore.phone } })}
          </p>
        </div>
      {:else}
        <div class="stack-2">
          <Button on:click={handleOnGoBack} block>{$t("goBack")}</Button>
          <p class="text-sm text-center">
            {$t("form.notFilled")}
          </p>
        </div>
      {/if}
    {:else}
      <form on:submit={handleSubmit} class="stack-6">
        <div class="bg-surface p-6 stack-6 rounded-lg shadow">
          <FormItem
            htmlFor="sms"
            label={$t("verificationCode.label")}
            error={$t($errors.verificationCode)}
          >
            <Input
              name="sms"
              id="sms"
              type="number"
              placeholder="******"
              pattern="[0-9]+"
              bind:value={$form.verificationCode}
            />
          </FormItem>
        </div>
        <div>
          <div class="cluster-6">
            {#if canRequestCode}
              <Button
                on:click={handleOnRequestCode}
                loading={isCodeRequesting}
                disabled={isCodeRequesting || isCodeVerifying}
                block>{$t("requestCode")}</Button
              >
            {:else}
              <Button on:click={handleOnRequestCode} disabled block
                >{$t("requestAgain", { values: { seconds: i } })}</Button
              >
            {/if}
            <Button
              type="primary"
              htmlType="submit"
              loading={isCodeVerifying}
              disabled={isCodeVerifying || isCodeRequesting}
              block>{$t("verifyCode")}</Button
            >
          </div>
        </div>
      </form>
    {/if}
  </div>
</Layout>
