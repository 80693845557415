<script>
  import sortBy from "lodash/sortBy";
  import { t, locale, locales } from "svelte-i18n";
  import { location, querystring, replace } from "svelte-spa-router";
  import FormItem from "./FormItem.svelte";
  import Select from "./Select.svelte";
  import isoLangs from "../utils/isoLangs";

  $: languages = $locales.map((locale) => ({
    value: locale,
    label: `${isoLangs[locale].nativeName} - ${isoLangs[locale].name}`,
  }));
  $: currentLanguage = languages.find((lng) => lng.value === $locale);
  $: sortedLanguages = sortBy(languages, ({ value }) => isoLangs[value].name);

  const handleSelect = (event) => {
    const lng = event.detail.value;

    // Update UI
    locale.set(lng);

    // Update URL query
    const searchParams = new URLSearchParams($querystring);
    searchParams.set("lang", lng);
    replace(`${$location}?${searchParams.toString()}`)
  };
</script>

<FormItem htmlFor="language" label={$t("language.label")}>
  <div>
    <Select
      id="language"
      value={currentLanguage}
      items={sortedLanguages}
      on:select={handleSelect}
    />
  </div>
</FormItem>
