<script>
  import { onDestroy, onMount } from "svelte";
  import { t, locale } from "svelte-i18n";
  import { push, querystring } from "svelte-spa-router";
  import Layout from "../components/Layout.svelte";
  import Button from "../primitives/Button.svelte";
  import { submittedFirstName, submittedUuid } from "../store";
  // import { addNotification } from "../store/notifications";

  const NUMBER_OF_TRIES = 10;
  const REPEAT_AFTER_MS = 10000;
  const TIME_BEFORE_FIRST_API_CALL_MS = 5000;

  let i = 0;
  let timeout;
  let status;
  let data;
  let url = "";
  let isLoading = false;

  const redirectToErrorScreen = () => {
    const searchParams = new URLSearchParams($querystring);
    searchParams.set("type", "thankYou");
    push(`/error?${searchParams.toString()}`)
  };

  const handleGetUrl = async () => {
    try {
      const res = await fetch("/api/geturl", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: $submittedUuid,
          lang: $locale,
        }),
      });

      data = await res.json();
      status = res.status;

      console.log(data, "data response");
    } catch (err) {
      console.log(err, "error");
      isLoading = false;
    }
  };

  $: if (status === 200 && data) {
    submittedUuid.set(null);
    submittedFirstName.set(null);
    isLoading = false;
    url = data.message;
    window.location.href = data.message;
    // addNotification({
    //   type: "success",
    //   message: $t(data?.message),
    // });
  } else if (i <= NUMBER_OF_TRIES) {
    i += 1;
    timeout = setTimeout(() => handleGetUrl(), REPEAT_AFTER_MS);
  } else if (i >= NUMBER_OF_TRIES) {
    isLoading = false;
    submittedUuid.set(null);
    submittedFirstName.set(null);
    redirectToErrorScreen();
  }

  onMount(() => {
    isLoading = true;
    timeout = setTimeout(handleGetUrl, TIME_BEFORE_FIRST_API_CALL_MS);
  });

  const handleSetUrl = () => {
    submittedUuid.set(null);
    submittedFirstName.set(null);
    window.location.href = url;
  };

  onDestroy(() => {
    clearInterval(timeout);
  });
</script>

<Layout
  title="thankYou.label"
  titleProps={{ values: { firstName: $submittedFirstName } }}
  description="thankYou.description"
>
  <Button
    type="primary"
    on:click={handleSetUrl}
    loading={isLoading}
    disabled={isLoading}
    block>{isLoading ? $t("pleaseWait") : $t("continue")}</Button
  >
</Layout>
