<script>
  import { t } from "svelte-i18n";
  import { pop, push } from "svelte-spa-router";
  import Layout from "../components/Layout.svelte";
  import Button from "../primitives/Button.svelte";
  import { pushWithQuerystring } from "../utils/routing";
</script>

<Layout title="emergency.label" description="emergency.description">
  <div class="cluster-6">
    <Button on:click={() => pop()} block>{$t("back")}</Button>
    <Button type="primary" on:click={() => pushWithQuerystring("/form")} block
      >{$t("continue")}</Button
    >
  </div>
</Layout>
