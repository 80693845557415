import { writable } from 'svelte/store';

export const notifications = writable([]);

export const closeNotification = (id) => {
  notifications.update((all) => all.filter((t) => t.id !== id));
};

export const addNotification = (notification) => {
  const id = Math.floor(Math.random() * 10000);

  const defaults = {
    id,
    type: 'info',
    dismissible: true,
    timeout: 3000,
  };

  const t = {
    ...defaults,
    ...notification,
  };
  notifications.update((all) => [t, ...all]);

  if (t.timeout) setTimeout(() => closeNotification(id), t.timeout);
};
