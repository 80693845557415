<script>
  import { locale } from "svelte-i18n";
  import { handleOnSetDirection } from "../i18n";
  import "../i18n";

  $: if ($locale) {
    handleOnSetDirection($locale);
  }
</script>

<slot />
