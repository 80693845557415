<script>
  import { v4 as uuidv4 } from "uuid";
  import { pop } from "svelte-spa-router";
  import { createForm } from "svelte-forms-lib";
  import { t } from "svelte-i18n";
  import FormItem from "./FormItem.svelte";
  import Input from "./Input.svelte";
  import InputTel from "./InputTel.svelte";
  import Button from "../primitives/Button.svelte";
  import { form as formStore } from "../store";
  import { isValidDate, isDateBeforeToday } from "../utils/date";
  import { pushWithQuerystring } from "../utils/routing";

  const YEAR_OF_BIRTH_CHAR_LENGTH = 4;

  let isSubmitting = false;
  let inputTel;
  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      uuid: uuidv4(),
      firstName: $formStore.firstName,
      middleName: $formStore.middleName,
      lastName: $formStore.lastName,
      email: $formStore.email,
      day: $formStore.day,
      month: $formStore.month,
      year: $formStore.year,
      phone: $formStore.phone,
    },
    validate: async (values) => {
      let errs = {};

      if (!values.firstName) {
        errs.firstName = $t("form.requiredField");
      }
      if (!values.lastName) {
        errs.lastName = $t("form.requiredField");
      }
      if (!values.email) {
        errs.email = $t("form.requiredField");
      }
      if (!values.phone) {
        errs.phone = $t("form.requiredField");
      }
      if (!values.day || !values.month || !values.year) {
        errs.dateOfBirth = $t("form.requiredField");
      }

      if (values.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        errs.email = $t("email.error");
      }

      if (values.phone && !inputTel?.isValidNumber()) {
        errs.phone = $t("phone.error");
      }

      if (
        (values.day &&
          values.month &&
          values.year &&
          !isValidDate(values.day, values.month, values.year)) ||
        !isDateBeforeToday(
          new Date(values.year, values.month - 1, values.day)
        ) ||
        values.year.length < YEAR_OF_BIRTH_CHAR_LENGTH
      ) {
        errs.dateOfBirth = $t("dateOfBirth.error");
      }

      return errs;
    },
    onSubmit: async (values) => {
      formStore.set({
        ...values,
        phone: inputTel ? inputTel.getNumber() : values.phone,
      });
      pushWithQuerystring("/verify");
    },
  });
</script>

<form on:submit={handleSubmit} class="stack-6 w-full">
  <div class="shadow-sm rounded-lg bg-surface p-6">
    <div class="stack-6">
      <FormItem
        htmlFor="first-name"
        label={$t("firstName.label")}
        error={$errors.firstName}
      >
        <Input
          name="first-name"
          id="first-name"
          bind:value={$form.firstName}
          disabled={isSubmitting}
        />
      </FormItem>
      <FormItem htmlFor="middle-name" label={$t("middleName.label")}>
        <Input
          name="middle-name"
          id="middle-name"
          placeholder={$t("optional")}
          bind:value={$form.middleName}
          disabled={isSubmitting}
        />
      </FormItem>
      <FormItem
        htmlFor="last-name"
        label={$t("lastName.label")}
        error={$errors.lastName}
      >
        <Input
          name="last-name"
          id="last-name"
          bind:value={$form.lastName}
          disabled={isSubmitting}
        />
      </FormItem>
      <FormItem htmlFor="email" label={$t("email.label")} error={$errors.email}>
        <Input
          name="email"
          id="email"
          bind:value={$form.email}
          disabled={isSubmitting}
        />
      </FormItem>
      <FormItem
        htmlFor="day"
        label={$t("dateOfBirth.label")}
        error={$errors.dateOfBirth}
      >
        <div class="grid grid-cols-3 gap-6">
          <FormItem htmlFor="day" label={$t("day.label")}>
            <Input
              name="day"
              id="day"
              type="number"
              maxlength="2"
              bind:value={$form.day}
              disabled={isSubmitting}
            />
          </FormItem>
          <FormItem
            htmlFor="month"
            label={$t("month.label")}
            error={$errors.month}
          >
            <Input
              name="month"
              id="month"
              type="number"
              maxlength="2"
              bind:value={$form.month}
              disabled={isSubmitting}
            />
          </FormItem>
          <FormItem htmlFor="year" label={$t("year.label")}>
            <Input
              name="year"
              id="year"
              type="number"
              maxlength={YEAR_OF_BIRTH_CHAR_LENGTH}
              bind:value={$form.year}
              disabled={isSubmitting}
            />
          </FormItem>
        </div>
      </FormItem>
      <FormItem htmlFor="phone" label={$t("phone.label")} error={$errors.phone}>
        <InputTel
          bind:input={inputTel}
          bind:value={$form.phone}
          disabled={isSubmitting}
        />
      </FormItem>
      <input
        name="uuid"
        id="uuid"
        type="hidden"
        bind:value={$form.uuid}
        class="sr-only"
      />
    </div>
  </div>

  <div>
    <div class="cluster-6">
      <Button on:click={() => pop()} block>{$t("back")}</Button>
      <Button
        htmlType="submit"
        type="primary"
        loading={isSubmitting}
        disabled={isSubmitting}
        block
      >
        {$t("submit")}
      </Button>
    </div>
  </div>
</form>
