import { writable } from "svelte/store";

export const sessionStore = (key, initial) => {
  const toString = (value) => JSON.stringify(value, null, 2);
  const toObj = JSON.parse;

  if (sessionStorage.getItem(key) === null) {
    sessionStorage.setItem(key, toString(initial));
  }

  const saved = toObj(sessionStorage.getItem(key));

  const { subscribe, set, update } = writable(saved);

  return {
    subscribe,
    set: (value) => {
      sessionStorage.setItem(key, toString(value));
      return set(value);
    },
    update,
  };
};
