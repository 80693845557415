<script>
  import { querystring } from "svelte-spa-router";
  import { t } from "svelte-i18n";
  import Layout from "../components/Layout.svelte";
  import Button from "../primitives/Button.svelte";
  import { pushWithQuerystring } from "../utils/routing";

  const handleOnGoBack = () => pushWithQuerystring("/form");

  $: searchParams = new URLSearchParams($querystring);
  $: type = searchParams.get("type");
</script>

<Layout title={`${type}.labelError`} description={`${type}.descriptionError`}>
  <Button type="primary" on:click={handleOnGoBack} block>{$t("enroll")}</Button>
</Layout>
