<script>
  /* eslint-disable no-redeclare */
  import Select from 'svelte-select';

  export let id;
  export let dataTestId;
  export let items = [];
  export let value;
  export let name;
  export let isMulti;
  export let isCreatable;
  export let isClearable = false;
  export let disabled;
  export let required;
  export let listAutoWidth;
  export let loadOptions;
  export let placeholder;

  // export let onSelect;

  $: inputAttributes = {
    name,
    required: required && !value,
  };

  const itemFilter = (label, filterText) => {
    if (!label) {
      return false;
    }

    return label.toLowerCase().includes(filterText.toLowerCase());
  };
</script>

<Select
  data-testid={dataTestId}
  containerClasses="themed"
  {id}
  {inputAttributes}
  {items}
  bind:value
  isDisabled={disabled}
  {isClearable}
  {isMulti}
  on:select
  on:clear
  {isCreatable}
  {itemFilter}
  {listAutoWidth}
  {loadOptions}
  {placeholder}
  hasError={required && !value}
/>
